import {InjectionKey} from 'vue'
import {createStore, Store} from 'vuex'

import {GeneralFlowInfo} from '@/Interfaces/GeneralFlowInfo'
import {OutputSources} from "@/Enums/OutputSources";
import {QuestionnairePage} from "@/Interfaces/QuestionnairePage";
import {PageTypes} from "@/Enums/PageTypes";
import {TonalTestFinalResult} from "@/Interfaces/HearingTestResult";
import {NoiseTestObjectData, NoiseTestResult} from "@/Interfaces/FinalResultsData";
import { SessionActionTypes } from '@/Enums/SessionActionTypes';
import evohealthState, { EvohealthState } from '@/vuex/evohealth.store';


// define your typings for the store state
export interface State {
    supportedLang: Array<string>
    generalFlowInfo: GeneralFlowInfo | null,
    questionAnswers: Array<number>,
    currentPageIndex: number | null,
    output: OutputSources | null
    originalPrepareAttenuation: number | null,
    tonalTestData: TonalTestFinalResult | null,
    noiseTestsData: Array<NoiseTestObjectData>,
    userInfoData: any,
    axiosRequestCase: string | null,
    showErrorNotification: boolean | null,
    finishLoading: boolean,
    leadSentId: null | number,
    solsticioPrediction: null | number,
    userDataSaved: DynamicObject,
    iframeSessionId: null | number,
    useLocalStorageForFormData: boolean | null,
    checkupAppInfoForBiosignsScan: null | any,
    hasAnyAudiologyTest: boolean | null,
    acceptanceText: string | null,
    marketingText: string | null,
    toPartnerText: string | null,
    privacyPolicyLink: string | null,
}

export interface DynamicObject {
    [key: string]: any
}


// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    modules: {
        evohealthState: evohealthState
    },
    state () :State {
        return {
            supportedLang: ['pt', 'en', 'es'],
            generalFlowInfo: null,
            questionAnswers: [],
            currentPageIndex: null,
            output: null,
            originalPrepareAttenuation: null,
            tonalTestData: null,
            noiseTestsData: [],
            userInfoData: {
                name: null,
                gender: null,
                address_city: null,
                address_line_1: null,
                address_postal_code: null,
                city: null,
                birthdate: null,
                email: null,
                phone: null,
            },
            axiosRequestCase: null,
            showErrorNotification: false,
            finishLoading: false,
            leadSentId: null,
            solsticioPrediction: null,
            userDataSaved: {},
            iframeSessionId: null,
            useLocalStorageForFormData: true,
            checkupAppInfoForBiosignsScan: null,
            hasAnyAudiologyTest: false,
            acceptanceText: null,
            marketingText: null,
            toPartnerText: null,
            privacyPolicyLink: null
        }
    },
    mutations: {
        handleGeneralFlowInfo(state, data:GeneralFlowInfo) {
            state.generalFlowInfo = data
        },
        setGender(state, gender: string) {
          state.userInfoData.gender = gender
        },
        updateCurrentPageIndex(state, idx: number) {
            state.currentPageIndex = idx
        },
        setPageFormData(state, params:{pageIdx: number, formData: {}}) {
            if(state.generalFlowInfo !== null) {
                state.generalFlowInfo.pages[params.pageIdx].formData = params.formData
            }
        },
        setOutput(state, output: OutputSources) {
            state.output = output
        },
        incrementCurrentQuestion(state, questionnairePageId) {
            const pages = state.generalFlowInfo!.pages.filter((page) => page.type === PageTypes.QUESTIONNAIRE_PAGE && page.questionnairePageId === questionnairePageId) as Array<QuestionnairePage>
            for (const page of pages) {
                page.currentQuestionIdx = page.currentQuestionIdx + 1
            }
        },
        decrementCurrentQuestion(state, questionnairePageId) {
            const pages = state.generalFlowInfo!.pages.filter((page) => page.type === PageTypes.QUESTIONNAIRE_PAGE && page.questionnairePageId === questionnairePageId) as Array<QuestionnairePage>
            for (const page of pages) {
                page.currentQuestionIdx = page.currentQuestionIdx -1
            }
        },
        /*resetCurrentQuestionIdx(state) {
            state.currentQuestionIdx = 1
        },*/
        setOriginalPrepareAttenuation(state, val) {
            state.originalPrepareAttenuation = val
        },
        setTonalTestData(state, data: TonalTestFinalResult) {
            state.tonalTestData = data
        },
        updateUserInfoData(state, data) {
            Object.keys(data).forEach(key => state.userInfoData[key] = data[key])
        },
        pushNoiseTest(state, data: NoiseTestObjectData) {
            state.noiseTestsData.push(data)
        },
        handleUserDataSaved(state, data: DynamicObject) {
            for(const key of Object.keys(data)) {
                state.userDataSaved[key] = data[key]
            }
        },
        storedLeadId(state, data) {
            state.leadSentId = data
        },
        setSolsticioPrediction(state, data) {
            state.solsticioPrediction = data
        },
        setSessionId(state, id: number) {
            state.iframeSessionId = id
        },
        setUseLocalStorageForFormData(state, shouldUse: boolean) {
            state.useLocalStorageForFormData = shouldUse
        },
        setCheckupAppInfoForBiosignsScan(state, data: any) {
            state.checkupAppInfoForBiosignsScan = data
        },
        setHasAnyAudiologyTest(state, bool: boolean) {
            state.hasAnyAudiologyTest = bool
        },
        setAcceptanceText(state, text: string) {
            state.acceptanceText = text
        },
        setMarketingText(state, text: string) {
            state.marketingText = text
        },
        setToPartnerText(state, text: string) {
            state.toPartnerText = text
        },
        setPrivacyPolicyLink(state, link: string) {
            state.privacyPolicyLink = link
        }
    },
    getters: {
        sessionActionTypes: () => {
            return [
                {
                    id: 1,
                    type: SessionActionTypes.THANKYOU_PAGE_CTA_CLICK
                }
            ];
        }
    }
})
